import { FSDataType } from "@/utils/filter-sort-param";
import { useQuery } from "@tanstack/react-query";

interface GetUsersByLengthProps {
  length: number;
  page: number;
  token: string | null | undefined;
  fsStr: FSDataType | '';
}

export const GetUsersByLength = ({ length = 10, page = 0, fsStr = '', token }: GetUsersByLengthProps) => {
  const { data, isLoading, error, refetch } = useQuery({
    queryKey: ['users', length, page, fsStr],
    queryFn: async () => {
      const res = await fetch(`${process.env.NEXT_PUBLIC_API_USER_URL}/user/users?length=${length}&page=${page}${fsStr}`, {
        cache: 'no-store',
        headers: {
          'Authorization': `Bearer ${token}`
        },
      });
      if (!res.ok) {
        throw new Error('Failed to fetch users');
      }

      const data = await res.json();
      return data?.data?.Users;
    },
    enabled: !!length,
  });

  return {
    data,
    isLoading,
    error,
    refetch
  };
};