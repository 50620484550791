import React, { useCallback, useEffect, useRef, useState } from "react";
import Link from "next/link";
import { AnimatePresence, motion } from "framer-motion";
import { Paperclip, SendIcon } from "lucide-react";
import { cn } from "@/lib/utils";
import { Button, buttonVariants } from "../ui/button";
import { Textarea } from "../ui/textarea";
import { EmojiPicker } from "./emoji-picker";
import { X } from "lucide-react";
import { DeleteButton } from "./chat-custom/delete-button";
import Visualizer from "./chat-custom/visualizer";
import RecordButton from "./chat-custom/record-button";
import { Timer } from "./chat-custom/timer";
import { useSocket } from "@/auth/provider/SocketContext";
import { sampleChatData } from "./sampleChatData";
import { loggedInUserData, Message } from "@/app/user/inbox/data";
import { extractFileName } from "./chat-list";
import lamejs from "lamejs";
import { convertWebMToWAV } from "./voice-record-helper";

interface ChatBottombarProps {
  sendMessage: (newMessage: Message) => void;
  isMobile: boolean;
  openReply: boolean;
  closeReply: () => void;
  openAttachDialog: boolean;
  closeAttachDialog: () => void;
  setOpenAttachDialog: (b: boolean) => void;
  attachQty: number;
  setAttachQty: (a: number) => void;
  senderId: string;
  agentId: string;
  selectedMessage: any | null;
  setSelectedMessage: (m: Message | null) => void;
  messages: Message[]
  setMessages: (m: any) => void;
  replySenderName?: string;
  conversationId: string;
  selectedUser: any,
  refetchConv: () => void,
  handleSeenEvent: () => void,
  inputRef: any
  myMessages: any
}

// Placeholder messages
const placeholders = [
  "Buy Order Confirmed.",
  "Buy Close Confirmed.",
  "Sell Order Confirmed.",
  "Sell Close Confirmed.",
]

// Recording chunks and timer timeout reference
let recordingChunks: BlobPart[] = []
let timerTimeout: NodeJS.Timeout

export default function ChatBottombarPrev({
  sendMessage,
  openReply,
  closeReply,
  openAttachDialog,
  setOpenAttachDialog,
  attachQty,
  setAttachQty,
  senderId,
  agentId,
  selectedMessage,
  setSelectedMessage,
  replySenderName,
  messages,
  setMessages,
  conversationId,
  selectedUser,
  refetchConv,
  handleSeenEvent,
  inputRef,
  myMessages
}: ChatBottombarProps) {
  const [message, setMessage] = useState("")

  const fileInputRef = useRef<HTMLInputElement>(null)

  const [imagePreviews, setImagePreviews] = useState<File[]>([]);
  const [nonImageFiles, setNonImageFiles] = useState(0);
  const [attachedFiles, setAttachedFiles] = useState<File[]>([]);
  const { socket, status } = useSocket();

  useEffect(() => {
    if (!socket) return;

    // Define the notification sound

    socket.on("support-conversations", (data) => {
      const socketMessageInfo = data?.InboxChunks?.[0];
      // console.log("socketMessageInfo", socketMessageInfo);
      const socketMessageAgentInfo = data?.conversation?.participants[0];
      refetchConv();
      const isMyMessage = myMessages?.some((b: any) => b?.id === data?.conversation?._id);
      // Attempt to play the sound
      if (data?.action === "sent" && socketMessageInfo?.messages?.[0]?.source?.platform === "whatsapp" && isMyMessage) {
        const notificationSound = new Audio('/music/livechatsound.mp3');
        notificationSound.oncanplaythrough = () => console.log("Audio loaded successfully from bottombar.");
        notificationSound.onerror = (error) => console.error("Error loading audio:", error);

        notificationSound.play().catch((error) => {
          console.error("Error playing sound:", error);
        });
      }
      else if (data?.action == "distributed" && isMyMessage) {
        const notificationSound = new Audio('/music/livechatsound.mp3');
        notificationSound.oncanplaythrough = () => console.log("Audio loaded successfully from bottombar.");
        notificationSound.onerror = (error) => console.error("Error loading audio:", error);

        notificationSound.play().catch((error) => {
          console.error("Error playing sound:", error);
        });
      }

      if (
        (socketMessageInfo.messages?.[0]?.content?.length > 0 ||
          socketMessageInfo?.messages?.[0]?.media?.length > 0) &&
        data?.conversation?._id === conversationId
      ) {
        const newMessage = {
          id: socketMessageInfo.messages[0]?._id,
          message: socketMessageInfo.messages[0]?.content,
          senderId: socketMessageInfo.messages[0]?.sender,
          agentId: socketMessageAgentInfo.id,
          isMySide: socketMessageInfo?.messages[0]?.sender !== senderId,
          media: socketMessageInfo?.messages[0]?.media,
          messageType: socketMessageInfo?.messages[0]?.type,
          avatar: selectedUser?.attributes?.profile_photo?.[0],
          context: socketMessageInfo?.messages[0]?.context,
          sentTime: data?.conversation.updatedAt,
          isFile: socketMessageInfo?.messages[0]?.media?.length > 0,
          sender: socketMessageInfo?.messages[0]?.sender,
        };

        setMessages((prevMessages: any) => {
          const existingMessageIndex = prevMessages.findIndex(
            (msg: any) => msg.id === newMessage.id
          );

          if (existingMessageIndex !== -1) {
            // Update existing message
            const updatedMessages = [...prevMessages];
            updatedMessages[existingMessageIndex] = {
              ...updatedMessages[existingMessageIndex],
              ...newMessage,
            };
            return updatedMessages;
          } else {
            // Add new message
            return [...prevMessages, newMessage];
          }
        });
      }
    });

    return () => {
      socket.off("support-conversations");
    };
  }, [socket, conversationId, senderId, selectedUser]);


  const handleFileChange = (event: any) => {
    const files = event.target.files
    if (files && files.length > 0) {
      setOpenAttachDialog(true);
      setAttachQty(files.length);

      const fileArray: File[] = Array.from(files);

      // Separate images and non-images
      const imageFiles: File[] = [];
      const nonImageFiles: File[] = [];

      fileArray.forEach((file) => {
        if (file.type.startsWith("image/")) {
          imageFiles.push(file); // Keep actual File objects
        } else {
          nonImageFiles.push(file); // Keep actual File objects
        }
      })

      setAttachedFiles(nonImageFiles); // For non-image files
      setNonImageFiles(nonImageFiles.length);
      setImagePreviews(imageFiles); // For image files, actual file objects
    }
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (event.target.value.length === 1) {
      handleSeenEvent();
    }
    setMessage(event.target.value)
  }

  const handleSend = () => {
    handleSeenEvent();
    if (
      message.trim() ||
      attachedFiles.length > 0 ||
      imagePreviews.length > 0
    ) {
      const newMessage: Message = {
        id: new Date().getTime(),
        name: loggedInUserData.name,
        avatar: loggedInUserData.avatar,
        message: message.trim(),
        attachments: attachedFiles,
        attachImages: imagePreviews,
        isFile: attachedFiles.length > 0 || imagePreviews.length > 0,
        sender: senderId,
        agent: agentId,
        contextId: selectedMessage?.id || null
      };
      // console.log(newMessage);
      sendMessage(newMessage)
      setMessage("")
      setSelectedMessage(null);
      setAttachedFiles([])
      setImagePreviews([])
      setOpenAttachDialog(false)
      setAttachQty(0)

      closeReply();

      if (inputRef.current) {
        inputRef.current.focus()
      }
    }
  }

  const handleSendFixedMsg = (fixMsg: string) => {
    if (fixMsg.trim()) {
      const newMessage: Message = {
        id: Date.now(),
        name: loggedInUserData.name,
        avatar: "",
        message: fixMsg.trim(),
        sender: senderId,
        agent: agentId,
        contextId: selectedMessage?.id || null
      };
      sendMessage(newMessage);
      setMessage("");
      setSelectedMessage(null);
      closeReply();

      if (inputRef.current) {
        inputRef.current.focus()
      }
    }
  }

  // Audio recording states
  const [isRecording, setIsRecording] = useState(false)
  const [timer, setTimer] = useState(0)
  const [recordingChunks, setRecordingChunks] = useState<Blob[]>([])
  const [recordedFile, setRecordedFile] = useState<File | null>(null)

  const [seenTriggered, setSeenTriggered] = useState(false);

  const mediaRecorderRef = useRef<{
    stream: MediaStream | null
    analyser: AnalyserNode | null
    audioContext: AudioContext | null
    recorder: MediaRecorder | null
  }>({ stream: null, analyser: null, audioContext: null, recorder: null })

  const timerRef = useRef<NodeJS.Timeout | null>(null)

  const startRecording = useCallback(() => {
    navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
      setIsRecording(true);
      setTimer(0);
      setRecordingChunks([]); // Clear previous recording chunks

      const audioCtx = new (window.AudioContext || (window as any).webkitAudioContext)();
      const analyser = audioCtx.createAnalyser();
      const source = audioCtx.createMediaStreamSource(stream);
      source.connect(analyser);

      const options = {
        mimeType: "audio/webm", // Use supported format
      };

      const recorder = new MediaRecorder(stream, options);

      recorder.ondataavailable = (e) => {
        if (e.data.size > 0) {
          setRecordingChunks((chunks) => [...chunks, e.data]);
        }
      };

      recorder.onerror = (e) => {
        console.error("MediaRecorder error:", e);
      };

      recorder.start(100); // Request data every 100ms
      console.log("MediaRecorder started");

      mediaRecorderRef.current = { stream, recorder, audioContext: audioCtx, analyser };

      timerRef.current = setInterval(() => setTimer((prev) => prev + 1), 1000);
    }).catch((error) => {
      console.error("Error accessing microphone:", error);
    });
  }, []);


  const stopRecording = useCallback(() => {
    const { stream, recorder } = mediaRecorderRef.current;

    if (stream && recorder) {
      recorder.stop();
      stream.getTracks().forEach((track) => track.stop());
      console.log("MediaRecorder stopped");
    }

    setIsRecording(false);

    if (timerRef.current) {
      clearInterval(timerRef.current);
    }
    setTimer(0);

    if (recordingChunks.length > 0) {
      const audioBlob = new Blob(recordingChunks, { type: "audio/webm" });
      console.log("Recording complete, converting to MP3...");
      convertToMP3(audioBlob); // Trigger MP3 conversion
    } else {
      console.error("No recording chunks available");
    }
  }, [recordingChunks]);


  const convertToMP3 = async (webMBlob: Blob) => {
    debugger
    console.log("Starting MP3 conversion...");

    try {
      const wavBuffer = await convertWebMToWAV(webMBlob); // Helper function for WAV conversion
      const wavHeader = lamejs.WavHeader.readHeader(new DataView(wavBuffer));

      if (!wavHeader) {
        throw new Error("Invalid WAV header");
      }

      const samples = new Int16Array(wavBuffer, wavHeader.dataOffset, wavHeader.dataLen / 2);
      const mp3encoder = new lamejs.Mp3Encoder(wavHeader.channels, wavHeader.sampleRate, 128);

      const mp3Data = [];
      let mp3Buffer = mp3encoder.encodeBuffer(samples);
      if (mp3Buffer.length > 0) mp3Data.push(mp3Buffer);

      mp3Buffer = mp3encoder.flush();
      if (mp3Buffer.length > 0) mp3Data.push(mp3Buffer);

      const mp3Blob = new Blob(mp3Data, { type: "audio/mp3" });
      const mp3File = new File([mp3Blob], `Audio_${Date.now()}.mp3`, { type: "audio/mp3" });

      console.log("MP3 conversion successful:", mp3File);
      setRecordedFile(mp3File); // Update state with the MP3 file
    } catch (error) {
      console.error("Error during MP3 conversion:", error);
    }
  };



  useEffect(() => {
    if (recordedFile) {
      console.log("Recorded file ready for sending:", recordedFile);

      const newMessage: Message = {
        id: Date.now(),
        name: loggedInUserData.name,
        avatar: loggedInUserData.avatar,
        message: "",
        attachments: [recordedFile],
        attachImages: [],
        isFile: true,
        isAudio: true,
        sender: senderId,
        agent: agentId,
      };

      try {
        sendMessage(newMessage);
        console.log("Audio message sent successfully");
        setRecordedFile(null); // Reset after sending
      } catch (error) {
        console.error("Error sending message:", error);
      }
    }
  }, [recordedFile, sendMessage, senderId, agentId]);


  const handleClick = () => { if (fileInputRef.current) { fileInputRef.current.click() } }

  const resetRecording = useCallback(() => {
    const { stream } = mediaRecorderRef.current
    if (stream) {
      stream.getTracks().forEach((track) => track.stop())
    }

    setIsRecording(false)
    if (timerRef.current) {
      clearInterval(timerRef.current)
    }
    setTimer(0)
    setRecordingChunks([])
  }, [])

  const handleKeyPress = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault()
      handleSend()
    }

    if (event.key === "Enter" && event.shiftKey) {
      event.preventDefault()
      setMessage((prev) => prev + "\n")
    }
  }

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [conversationId])

  return (
    <>
      {openReply && (
        <div className="border-y bg-background/95 p-4 backdrop-blur supports-[backdrop-filter]:bg-accent/60">
          <div className="flex flex-row items-center justify-between rounded-sm bg-primary/10 p-4">
            <div>
              <h2 className="font-bold text-green-400">{replySenderName}</h2>
              <p className="mt-1">{selectedMessage?.media?.length > 0 ? extractFileName(selectedMessage?.media?.[0].path) : selectedMessage?.message}</p>
            </div>
            <X onClick={closeReply} className="size-4 cursor-pointer" />
          </div>
        </div>
      )}
      {openAttachDialog && (
        <div className="mx-16 rounded-sm border bg-background/95 p-4">
          <div className="flex flex-row items-center justify-between rounded-sm bg-gray-600/30 p-4 backdrop-blur supports-[backdrop-filter]:bg-gray-500/60">
            <div>
              <h2>
                {imagePreviews.length > 0 && (
                  <div className="flex space-x-2">
                    {imagePreviews.slice(0, 5).map((file: File, index) => {
                      const src = URL.createObjectURL(file); // Create a temporary URL for the File object
                      return (
                        <img
                          key={index}
                          src={src}
                          alt={`preview-${index}`}
                          className="size-20 object-cover"
                          onLoad={() => URL.revokeObjectURL(src)} // Release memory after the image loads
                        />
                      );
                    })}
                    {imagePreviews.length > 5 && (
                      <span className="text-sm text-muted-foreground">
                        +{imagePreviews.length - 5} more images
                      </span>
                    )}
                  </div>
                )}

                {nonImageFiles > 0 && (
                  <p className="mt-2">
                    {`${imagePreviews.length > 0 ? "and" : ""
                      } ${nonImageFiles} ${nonImageFiles > 1 ? "files" : "file"
                      } attached`}
                  </p>
                )}
              </h2>
            </div>
            <X
              onClick={() => {
                setOpenAttachDialog(false)
                setImagePreviews([])
                setAttachQty(0)
                setAttachedFiles([])
              }}
              className="size-4 cursor-pointer"
            />
          </div>
        </div>
      )}
      <div className="bg-background/95 pb-2 pt-4 backdrop-blur supports-[backdrop-filter]:bg-background/60">
        {/* {!openAttachDialog && (
          <div className="grid grid-cols-2 justify-evenly gap-4 pb-4 pl-16 pr-20 lg:grid-cols-4">
            {placeholders.map((item, index) => (
              <Button
                onClick={() => {
                  handleSendFixedMsg(item)
                }}
                className={`${item?.includes("Buy")
                  ? "border-green-400 hover:bg-green-400/20"
                  : "border-red-400 hover:bg-red-400/20"
                  } whitespace-normal break-words text-center`}
                size="sm"
                variant="outline"
                key={index}
              >
                {item}
              </Button>
            ))}
          </div>
        )} */}
        <div className="flex w-full items-center justify-between gap-2 px-4 py-2">
          <div className="flex">
            {!isRecording ? (
              <>
                <Button variant="outline" size="icon" onClick={handleClick}>
                  <Paperclip size={20} className="text-muted-foreground" />
                </Button>
                <input
                  type="file"
                  ref={fileInputRef}
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                  multiple
                />
              </>
            ) : (
              <DeleteButton onResetRecording={resetRecording} />
            )}
          </div>

          <AnimatePresence initial={false}>
            <motion.div
              key="input"
              className="relative w-full"
              layout
              initial={{ opacity: 0, scale: 1 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 1 }}
              transition={{
                opacity: { duration: 0.05 },
                layout: {
                  type: "spring",
                  bounce: 0.15,
                },
              }}
            >
              {!isRecording ? (
                <>
                  <Textarea
                    autoComplete="off"
                    value={message}
                    ref={inputRef}
                    onKeyDown={handleKeyPress}
                    onChange={handleInputChange}
                    name="message"
                    placeholder="Type here..."
                    className="flex h-10 resize-none items-center overflow-hidden rounded-md border bg-background"
                    onFocus={handleSeenEvent}
                    onClick={handleSeenEvent}
                    autoFocus
                  />
                  <div className="absolute bottom-1 right-2">
                    <EmojiPicker
                      onChange={(value) => {
                        setMessage(message + value)
                        if (inputRef.current) {
                          inputRef.current.focus()
                        }
                      }}
                    />
                  </div>
                </>
              ) : (
                <Visualizer
                  isRecording={isRecording}
                  analyser={mediaRecorderRef.current?.analyser || null}
                  timer={`${String(Math.floor(timer / 60)).padStart(2, "0")}:${String(timer % 60).padStart(2, "0")}`}
                />
              )}
            </motion.div>

            {message.trim() || attachQty > 0 ? (
              <Button
                onClick={handleSend}
                size="icon"
                variant="outline"
                className="mr-3"
              >
                <SendIcon size={20} />
              </Button>
            ) : (
              <RecordButton
                onStartRecording={startRecording}
                onStopRecording={stopRecording}
                isRecording={isRecording}
              />
            )}
          </AnimatePresence>
        </div>
      </div>
    </>
  )
}
