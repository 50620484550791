"use client"
import { cn } from "@/lib/utils"
import DynamicTabsCustom from "../custom/dynamic-tabs-custom"
import { Message } from "@/app/admin/inbox/data"
import CustomAvatar from "./chat-custom/custom-avatar"
import { IoLogoWhatsapp } from "react-icons/io";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { useEffect, useState } from "react"
import { FaSpinner } from "react-icons/fa"

interface SidebarProps {
  links: {
    firstName: string
    lastName: string
    messages: Message[]
    avatar: string
    active: boolean
    changeConversation?: () => void
    initId: any,
    initUid: any,
    uid: any,
    fullName: any,
    assignee?: string
    phone: string
    lastMsgTime: string,
    unreadMsg: number
  }[]
  onClick?: () => void
  isLoadingConversations?: boolean
  isUserOrVendorLoading?: boolean
  myMessages: {
    firstName: string
    lastName: string
    messages: Message[]
    avatar: string
    active?: boolean
    changeConversation?: () => void
    initId: any,
    initUid: any,
    uid: any,
    fullName: any,
    assignee?: string
    phone: string
    lastMsgTime: string
    unreadMsg: number
  }[]
  conversationList: any
  conversationId: string
  setConversationId: (conversationId: string) => void
  setSelectedUser: (user: any) => void
  setNewMessageSender: (id: string) => void
  setNewMessageRecipient: (id: string) => void
  setIsNewMessage: (b: boolean) => void
  setMySide: any
  setSelectedVendor: any,
  vendorQueries: any
}

export function Sidebar({
  links,
  isLoadingConversations,
  isUserOrVendorLoading,
  myMessages,
  conversationList,
  conversationId,
  setConversationId,
  setSelectedUser,
  setNewMessageSender,
  setNewMessageRecipient,
  setIsNewMessage,
  setMySide,
  setSelectedVendor,
  vendorQueries
}: SidebarProps) {
  const [allConvs, setAllConvs] = useState(links);
  const [allConvsReset, setAllConvsReset] = useState(links);
  const [myConvs, setMyConvs] = useState(myMessages);
  const [myConvsReset, setMyConvsReset] = useState(myMessages);

  useEffect(() => {
    // console.log("Refetch from useEffect");
    setAllConvs(links);
    setMyConvs(myMessages);
    setAllConvsReset(links);
    setMyConvsReset(myMessages);
  }, [conversationList, isUserOrVendorLoading, conversationId]);

  const convTabs = [
    {
      value: "allmessages",
      label: "All Messages",
      content: (
        <>
          <nav className="grid gap-2 p-4">
            {(isLoadingConversations || isUserOrVendorLoading) ? <div className="flex justify-center"><FaSpinner className="animate-spin h-8 w-8" /></div> : allConvs?.length > 0 ? allConvs.map((link, index) => (
              <div
                key={index}
                onClick={link.changeConversation}
                className={cn(
                  "flex cursor-pointer items-center gap-4 rounded-md p-2 hover:bg-muted-foreground/10",
                  link.active && "bg-muted-foreground/10"
                )}
              >
                {/* : link?.unreadMsg > 0 ? "bg-red-400/10" : "" */}
                <CustomAvatar src={link.avatar} alt={link.avatar} icon={<IoLogoWhatsapp size={12} className="text-green-600" />} />

                <div className="flex w-full items-center justify-between">
                  <div className="flex flex-col justify-between gap-1">
                    <div className="flex gap-2">
                      <span className="max-w-[180px] truncate text-sm">
                        {
                          (!link.firstName && !link.lastName) ? !link.phone ? "Syncing User.." : link.phone : !link.firstName ? link.lastName : !link.lastName ? link.firstName : link.firstName + " " + link.lastName
                        }
                      </span>
                    </div>

                    {/* {link.messages.length > 0 && (
                      )} */}
                    <span className="flex items-center gap-1 truncate text-[11px] text-muted-foreground">
                      <p>{link?.initId !== "@undefined" ? link?.initId : "@"}</p>
                      {(link?.uid === undefined || link?.uid === null) ? (
                        <p className="flex items-center gap-1">
                          No Account <AiOutlineExclamationCircle className="text-red-400" />
                        </p>
                      ) : (
                        <p>{link?.uid}</p>
                      )}

                    </span>
                    <span className={`truncate text-xs ${link.assignee === "Not Assigned" ? 'text-red-400' : link.assignee === "Closed" ? 'text-gray-400' : 'text-green-600'}`}>
                      {link.assignee}
                    </span>
                  </div>
                  <div className="flex flex-col items-end justify-start gap-3">
                    <span className="text-xs" style={{ color: link?.unreadMsg > 0 ? "#fe0000" : "" }}>{link.lastMsgTime}</span>
                    {
                      link?.unreadMsg > 0 ?
                        <span className="rounded-full px-2 py-0.5 text-xs font-semibold text-white" style={{ backgroundColor: "#fe0000" }}>
                          {link?.unreadMsg}
                        </span> :
                        <>
                          <div className="py-0.5"></div>
                          <div ></div>
                        </>
                    }
                    <div></div>
                  </div>
                </div>
              </div>
            )) : <div className="text-center">No conversation found!</div>}
          </nav>
        </>
      ),
    },
    {
      value: "mymessages",
      label: "My Messages",
      content: (
        <>
          <nav className="grid gap-2 p-4">
            {(isUserOrVendorLoading || isLoadingConversations) ? <div className="text-center"><FaSpinner className="animate-spin h-8 w-8" /></div> : myConvs?.length > 0 ? myConvs.map((link, index) => (
              <div
                key={index}
                onClick={link.changeConversation}
                className={cn(
                  "flex cursor-pointer items-center gap-4 rounded-md p-2 hover:bg-muted-foreground/10",
                  link.active && "bg-muted-foreground/10"
                )}
              >
                <CustomAvatar src={link.avatar} alt={link.avatar} icon={<IoLogoWhatsapp size={12} className="text-green-600" />} />

                <div className="flex w-full items-center justify-between">
                  <div className="flex flex-col justify-between gap-1">
                    <div className="flex gap-2">
                      <span className="text-sm truncate max-w-[180px]">
                        {
                          (!link.firstName && !link.lastName) ? !link.phone ? "Syncing User.." : link.phone : !link.firstName ? link.lastName : !link.lastName ? link.firstName : link.firstName + " " + link.lastName
                        }
                      </span>
                    </div>

                    {/* {link.messages.length > 0 && (
                    )} */}
                    <span className="flex items-center gap-1 truncate text-[11px] text-muted-foreground">
                      <p>{link?.initId !== "@undefined" ? link?.initId : "@"}</p>
                      {link?.uid === undefined ? (
                        <p className="flex items-center gap-1">
                          No Account <AiOutlineExclamationCircle className="text-red-400" />
                        </p>
                      ) : (
                        <p>{link?.uid}</p>
                      )}

                    </span>
                    <span className={`truncate text-xs ${link.assignee === "Not Assigned" ? 'text-red-400' : link.assignee === "Closed" ? 'text-gray-400' : 'text-green-600'}`}>
                      {link.assignee}
                    </span>
                  </div>
                  <div className="flex flex-col items-end justify-start gap-3">
                    <span className="text-xs" style={{ color: link?.unreadMsg > 0 ? "#fe0000" : "" }}>{link.lastMsgTime}</span>
                    {
                      link?.unreadMsg > 0 ?
                        <span className="rounded-full px-2 py-0.5 text-xs font-semibold text-white" style={{ backgroundColor: "#fe0000" }}>
                          {link?.unreadMsg}
                        </span> :
                        <>
                          <div className="py-0.5"></div>
                          <div ></div>
                        </>
                    }
                    <div></div>
                  </div>
                </div>
              </div>
            )) : <div className="text-center">No conversation found!</div>}
          </nav>
        </>
      ),
    },
  ]
  return (
    <div className="flex h-full  flex-col gap-4 border-r">
      <DynamicTabsCustom
        tabs={convTabs}
        conversationList={conversationList}
        setConversationId={setConversationId}
        setSelectedUserInfo={setSelectedUser}
        setNewMessageSender={setNewMessageSender}
        setNewMessageRecipient={setNewMessageRecipient}
        setIsNewMessage={setIsNewMessage}
        setAllConvs={setAllConvs}
        setMyConvs={setMyConvs}
        allConvsReset={allConvsReset}
        myConvsReset={myConvsReset}
        setMySide={setMySide}
        setSelectedVendor={setSelectedVendor}
        vendorQueries={vendorQueries}
      />
    </div>
  )
}
