"use client"

import { useEffect, useState } from "react"
import { GetBusinessOwenerByManager } from "@/api_store/queries/user/useBusinessesByManager"
import { useGetUsers } from "@/api_store/queries/user/useUser"
import { GetUsersByLength } from "@/api_store/queries/user/userQuery"
import { getToken } from "@/auth/config/keycloak"
import { useKeycloak } from "@/auth/provider/KeycloakProvider"
import { buildFSStr } from "@/utils/filter-sort-param"

import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog"
import { Label } from "@/components/ui/label"
import AutocompleteDropdown from "@/components/custom/autocomplete-dropdown"

import { Button } from "../ui/button"
import SearchableDropdown from "./searchable-dropdown"

export function ConversationAddDialogNew({
  open,
  close,
  selectedUser,
  setSelectedUser,
  selectedBusiness,
  setSelectedBusiness,
  handleSubmit,
  setUserDataInfo,
}) {
  const [userOptions, setUserOptions] = useState([])
  const [userSearchText, setUserSearchText] = useState("")
  const [fsStr, setFsStr] = useState("")

  const [businessOptions, setBusinessOptions] = useState([])
  const [businessSearchText, setBusinessSearchText] = useState("")
  const [businessFsStr, setBusinessFsStr] = useState("")
  const [businessIds, setBusinessIds] = useState([])

  const { user } = useKeycloak()

  const length = 10
  const page = 1

  // hooks
  const token = getToken()

  const {
    data: businessData,
    isLoading: isLoadingBusinesses,
    refetch: refetchBusinesses,
  } = GetBusinessOwenerByManager({ managerId: user?.userId, token })

  useEffect(() => {
    if (businessData) {
      setBusinessIds(businessData)
    }
  }, [businessData])

  const businesses = useGetUsers(businessIds)

  const {
    data: userData,
    isLoading,
    error,
    refetch: refetchUsers,
  } = GetUsersByLength({ length, page, token, fsStr })

  const handleSearchUser = (value) => {
    setUserSearchText(value)

    if (value.length <= 0) {
      setFsStr("")
      refetchUsers()
      return
    }

    const searchFilter = [
      [["firstName", "like", value]],
      [["lastName", "like", value]],
      [["uid", "eq", value]],
      [["phone", "eq", encodeURIComponent(value)]],
    ]

    const dt = buildFSStr({ filters: searchFilter, sort: null })
    setFsStr(dt)
  }

  const handleSearchBusiness = (value) => {
    setBusinessSearchText(value)

    if (value.length <= 0) {
      setBusinessFsStr("")
      return
    }

    const searchFilter = [
      [["firstName", "like", value]],
      [["lastName", "like", value]],
      [["phone", "eq", encodeURIComponent(value)]],
    ]
    const dt = buildFSStr({ filters: searchFilter, sort: null })
    setBusinessFsStr(dt)
  }

  useEffect(() => {
    if (userData) {
      setUserDataInfo(userData)
      const transformedData = transformUserDataForOptions()
      setUserOptions(transformedData)
    }
  }, [userData])

  useEffect(() => {
    if (businesses) {
      const formatData = businesses.map((business) => ({
        value: business?.data?.id,
        label: business?.data?.firstName + " " + business?.data?.lastName,
      }))
      setBusinessOptions(formatData)
    }
  }, [open])

  useEffect(() => {
    if (!open) {
      setSelectedUser(null)
      setSelectedBusiness(null)
      setUserSearchText("")
      setBusinessSearchText("")
    }
  }, [open])

  const transformUserDataForOptions = () => {
    return userData?.map((user) => ({
      id: user.id,
      name: `${user.firstName?.length > 0 ? user.firstName + " " + user.lastName : user.username} (${user.uid})`,
      subtitle: user?.phone,
      avatarUrl: user?.attributes?.profile_photo?.[0],
    }))
  }

  return (
    <Dialog open={open} onOpenChange={close}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>New Message</DialogTitle>
        </DialogHeader>
        <div className="grid items-center gap-4 py-4">
          <div className="mt-4 grid grid-cols-4 items-center gap-4">
            <Label htmlFor="business" className="text-left leading-6">
              Business:
            </Label>
            <AutocompleteDropdown
              options={businessOptions}
              value={selectedBusiness}
              onChange={setSelectedBusiness}
              placeholder="Search Business..."
              dropdownLabel="Select a Business..."
              searchText={businessSearchText}
              setSearchText={handleSearchBusiness}
            />
          </div>
          <div className="mt-4 grid grid-cols-4 items-center gap-4">
            <Label htmlFor="contact" className="text-left">
              Contact:
            </Label>
            <div className="col-span-3">
              <SearchableDropdown
                users={userOptions}
                searchQuery={userSearchText}
                setSearchQuery={setUserSearchText}
                onSelect={setSelectedUser}
                onSearch={handleSearchUser}
                selectedUserId={selectedUser}
              />
            </div>
          </div>
        </div>
        <DialogFooter>
          <Button variant="default" onClick={handleSubmit}>
            Continue
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}