"use client"

import React, { useEffect, useState, useRef } from "react"
import { BsThreeDotsVertical } from "react-icons/bs"
import { FaCalculator } from "react-icons/fa6"
import { VscCloseAll } from "react-icons/vsc"
import html2canvas from 'html2canvas'

import { AEDConversionRate, calculateCurrentValueUSD, calculateEffectiveWeight, calculateMarginLimit, calculateTradingFeeInDollar, cn, metalObj, netBalanceCalculation, totalProfitLoss } from "@/lib/utils"
import { useSidebarInbox } from "@/hooks/use-sidebar-inbox"
import { useStore } from "@/hooks/use-store"
import { useToast } from "@/components/ui/use-toast"

import Actions from "./chat/actions"
import ActiveTrades from "./chat/active-trades"
import PendingTrades from "./chat/pending-trades"
import { Button } from "./ui/button"
import { Label } from "./ui/label"
import { Popover, PopoverContent, PopoverTrigger } from "./ui/popover"
import { Separator } from "./ui/separator"
import { useGetUserByPhone } from "@/api_store/queries/tradeInfo/getUserByPhone"
import { getVendorByPhone } from "@/api_store/queries/tradeInfo/getVendorByPhone"
import NotFoundAnimation from "./custom/lottie-not-found-animation"
import { useGetUserInfoById } from "@/api_store/queries/tradeInfo/useGetUserInfoById"
import { useCloseAllTrades } from "@/api_store/mutations/buyTrades/useCloseAllTrades"
import { useKeycloak } from "@/auth/provider/KeycloakProvider"
import { useTheme } from "next-themes"
import { BsGraphUpArrow } from "react-icons/bs";
import DynamicDialog from "./custom/dynamic-dialog"
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from "./ui/select"
import { GetActiveTrades } from "@/api_store/queries/activTrades/getActiveTrades"
import CustomAvatar from "./chat/chat-custom/custom-avatar"
import PDFGenerator from "./custom/pdf-generator/generatePDF"
import ActivePDFGenerator from "./custom/pdf-generator/generateActivePDF"
import { useGenerateStatement } from "@/api_store/queries/tradinStatements/generateStatementData"
import { Switch } from "./ui/switch"
import { useAutoStatement } from "@/api_store/mutations/buyTrades/autoStatementPost"
import { IoMdArrowRoundUp } from "react-icons/io";
import { IoMdArrowRoundDown } from "react-icons/io";
import { generateAndSendPDF } from "./custom/pdf-generator/generateAndSendPDF"
import TradingPosition from "./custom/trading-position"
import { ArrowDown, ArrowUp } from "lucide-react"
import { GetLogs } from "@/api_store/queries/activTrades/getLogs"
import { TbReportMoney } from "react-icons/tb";
import { Card, CardContent } from "./ui/card"
import { Avatar, AvatarFallback, AvatarImage } from "./ui/avatar"
import DynamicTabs from "./custom/dynamic-tabs"

interface ChatTopbarProps {
  selectedUser: any
  selectedVendor: any
  onSendMessage: (message: any) => void;
  conversationId: any
  onTradeFromMessage: (callback: (sTradeId: string) => void) => void;
  customerLastMessages: any;
  handleSeenEvent: () => void
}

interface TempMessageType {
  id: number;
  message: string;
  attachments: any[];
  attachImages: any[];
  conversationId: string;
}

function removePlusFromNumber(phoneNumber: string) {
  return phoneNumber?.replace('+', '');
}

const metalTypes = [
  { label: "TTB (116.52 gm)", value: "ttb" },
  { label: "KILOBAR (1000 gm)", value: "kilobar" },
  { label: "GRAM (1 gm)", value: "gram" },
  { label: "OUNCE (31.1 gm)", value: "ounce" },
]
interface ActionUser {
  id: number;
  name: string;
}

interface Transaction {
  id: number;
  amount: number;
  type: string;
  subType: string;
  updatedAt: string;
  actionUser: ActionUser;
}

interface Account {
  id: number;
  amount: number;
  charge: number;
  type: string;
  actionUser: ActionUser;
}

interface CompletedTrade {
  id: number;
  updatedAt: string;
  account: Account;
}

interface LogDataType {
  transactions: Transaction[];
  completedTrades: CompletedTrade[];
  total_deposit: number;
  total_withdraw: number;
  total_profit: number;
  total_loss: number;
}

function formatDate(isoString: string): string {
  const date = new Date(isoString);
  return new Intl.DateTimeFormat('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  }).format(date);
}

export function SidebarInbox({ selectedUser, onSendMessage, conversationId, selectedVendor, onTradeFromMessage, customerLastMessages, handleSeenEvent }: ChatTopbarProps) {
  const sidebar = useStore(useSidebarInbox, (state) => state)
  const [openAction, setOpenAction] = useState(false)
  const [refetchQuery, setRefetchQuery] = useState<() => void>(() => () => { })
  const [refetchPendingQuery, setRefetchPendingQuery] = useState<() => void>(() => () => { })

  const { tradeToken: token } = useKeycloak();

  const [askPrice, setAskPrice] = useState(0);
  const [bidPrice, setBidPrice] = useState(0);
  const [lowPrice, setLowPrice] = useState(0);
  const [highPrice, setHighPrice] = useState(0);
  const [phone, setPhone] = useState('');
  const [vendorPhone, setVendorPhone] = useState('');
  const [userName, setUserName] = useState('');
  const [uuId, setUuId] = useState('');
  const [userId, setUserId] = useState(0);

  const [vendorId, setVendorId] = useState(0);
  const [vendorInitId, setVendorInitId] = useState('');
  const [isUserAvailable, setIsUserAvailable] = useState(false);
  const [accountBalance, setAccountBalance] = useState(0);
  const [profitLoss, setProfitLoss] = useState("");
  const [netBalance, setNetBalance] = useState(0);
  const [marginLimit, setMarginLimit] = useState(0);
  const [statements, setStatements] = useState([]);
  const [openLog, setOpenLog] = useState(false);
  const [logItems, setLogItems] = useState<LogDataType>();

  const [isAtBottom, setIsAtBottom] = useState(false)
  const sidebarRef = useRef<HTMLDivElement>(null)
  const { theme } = useTheme();

  const [tempMessage, setTempMessage] = useState<TempMessageType>({ id: 0, message: '', attachments: [], attachImages: [], conversationId: '' });

  const [isScreenshotMode, setIsScreenshotMode] = useState(false);
  const screenshotRef = useRef(null);

  useEffect(() => {
    if (selectedUser?.attributes?.phone?.[0]) {
      setPhone(selectedUser?.attributes?.phone?.[0]);
    }
    else {
      setPhone('');
    }
  }, [selectedUser]);

  useEffect(() => {
    if (selectedVendor?.attributes?.phone?.[0]) {
      setVendorPhone(selectedVendor?.attributes?.phone?.[0]);
    }
  }, [selectedVendor]);

  const { data, isPending, error, refetch } = useGetUserByPhone({ phone: removePlusFromNumber(phone), token })

  useEffect(() => {
    if (data?.status && data?.message === "success") {
      const user = data?.data?.user;

      if (user === null) {
        setIsUserAvailable(false);
        setUserName("");
        setUuId('');
        setUserId(0);
        return;
      }
      else if (data?.data?.vendors?.length > 0) {
        const vendors = data?.data?.vendors;
        const isVendor = vendors.some((vendor: any) => vendor?.id === vendorId);
        if (!isVendor) {
          setIsUserAvailable(isVendor);
          setUserName("");
          setUuId('');
          setUserId(0);
          return;
        }
        setIsUserAvailable(isVendor);
      }

      setUserName(user?.name);
      setUuId(user?.uid);
      setUserId(user?.id);
    }
    else {
      setIsUserAvailable(false);
      refetch();
    }
  }, [data, vendorId]);

  const { data: vendors } = getVendorByPhone(removePlusFromNumber(vendorPhone), token)

  useEffect(() => {
    if (vendors?.status) {
      const dt = vendors?.data
      setVendorId(dt?.id);
      setVendorInitId(dt?.init_ID);
    }
  }, [vendors, selectedVendor]);

  // Statement data here
  const { data: statementData, refetch: statementRefetch } = useGenerateStatement(userId, token, vendorId) // this query should run when a new trade added or delete or update

  useEffect(() => {
    if (statementData?.status) {
      const dt = statementData?.data
      setStatements(dt);
    }
  }, [statementData?.data, statementData?.status]);
  // Statement data end here

  const { data: userInfo, refetch: userRefetch } = useGetUserInfoById(vendorInitId, uuId, token, vendorId);

  useEffect(() => {
    if (userInfo?.status) {
      const dt = userInfo?.data
      if (dt) {
        setAccountBalance(dt?.virtual_trading);
        const effectiveWeight = calculateEffectiveWeight(dt?.buyWeight, dt?.sellWeight);
        const mrgnLimit = calculateMarginLimit({
          goldPrice: { askPrice, bidPrice },
          balance: dt?.virtual_trading,
          buyWeight: dt?.buyWeight,
          sellWeight: dt?.sellWeight,
          netBuyPrice: dt?.netOpenPrice,
          effectiveWeight: effectiveWeight
        });
        setMarginLimit(mrgnLimit);
      }
    }
  }, [userInfo, uuId]);

  useEffect(() => {
    const userData = userInfo?.data
    if (userData) {
      const effectiveWeight = calculateEffectiveWeight(userData?.buyWeight, userData?.sellWeight);

      const curValueUSD = calculateCurrentValueUSD({
        goldPrice: { askPrice, bidPrice },
        buyWeightOz: userData?.buyWeight * 0.035274,
        sellWeightOz: userData?.sellWeight * 0.035274
      });

      const tradingFee = calculateTradingFeeInDollar(userData?.buyWeight + userData?.sellWeight);

      // console.log(`${tradingFee} is trading fee`);


      // const pnL = totalProfitLoss({
      //   currentVal: curValueUSD,
      //   tradingFee: tradingFee,
      //   totalVal: userData?.netOpenPrice
      // })

      let totalBuyWeightPrice: number = (userData?.buyWeight) * bidPrice
      let totalSellWeightPrice: number = (userData?.sellWeight) * askPrice
      let ozPerGram: number = +(1 / metalObj.OUNCE).toFixed(8)

      const pnL = ((totalBuyWeightPrice - totalSellWeightPrice) * ozPerGram - userData?.netOpenPrice - tradingFee)
      setProfitLoss((pnL * AEDConversionRate).toFixed(2));

      const nBalance = netBalanceCalculation({ balance: userData?.virtual_trading, profitLossAed: pnL * AEDConversionRate });
      setNetBalance(nBalance);
    }
  }, [userInfo, askPrice, bidPrice]);

  const { toast } = useToast()

  useEffect(() => {
    const ws = new WebSocket('wss://api.dreamemirates.com/OZzsZ1GQORWllqCY8lydekjQcsJT9a4k');

    ws.onopen = () => {
      console.log('Connected to WebSocket server');
    };

    ws.onmessage = (event) => {
      const newMessage = JSON.parse(event.data);
      if (newMessage) {
        setAskPrice(newMessage.askPrice);
        setBidPrice(newMessage.bidPrice);
        setLowPrice(newMessage.lowPrice);
        setHighPrice(newMessage.highPrice);
      }
    };

    ws.onclose = () => {
      console.log('WebSocket connection closed');
    };

    ws.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    return () => {
      if (ws) ws.close();
    };
  }, [])

  const { mutate: closeAllTrades, isPending: isLoading } = useCloseAllTrades({
    onSuccess: async (data) => {
      if (data?.status) {
        toast({
          variant: "default",
          className: "bg-green-600 text-white hover:bg-green-700",
          description: tempMessage?.message,
        })
        setOpenAction(false)
        refetchQuery();
        userRefetch();
        onSendMessage(tempMessage);
        handleSeenEvent();
        const { data: latestStatementData } = await statementRefetch();
        if (latestStatementData && autoStatementStatus) {
          await generateAndSendPDF(latestStatementData.data, handleSendStatement);
        }
      }
    },
    onError: (error) => {
      toast({
        variant: "destructive",
        description: "Server error. Please contact support!",
      })
      console.log(error)
    },
  });

  const handleCloseAllTrades = () => {
    if (activeTrades?.data?.result?.length <= 0) {
      toast({
        variant: "destructive",
        description: "No active trades found!",
      })
      setOpenAction(false)
      return
    }
    const tMessage = {
      id: Date.now(),
      message: "Your all trades have been closed!",
      attachments: [],
      attachImages: [],
      conversationId: conversationId
    }
    setTempMessage(tMessage);
    closeAllTrades({
      userId,
      token,
      vendorId,
      liveAskPrice: askPrice,
      liveBidPrice: bidPrice,
      tradeType: "",
    })
  }

  const handleCloseBuyTrades = () => {
    if (activeTrades?.data?.result?.filter((item: any) => item?.trade_type === "BUY")?.length <= 0) {
      toast({
        variant: "destructive",
        description: "No active buy trades found!",
      })
      setOpenAction(false)
      return
    }
    const tMessage = {
      id: Date.now(),
      message: "Your all buy trades have been closed!",
      attachments: [],
      attachImages: [],
      conversationId: conversationId
    }
    setTempMessage(tMessage);
    closeAllTrades({
      userId,
      token,
      vendorId,
      liveAskPrice: askPrice,
      liveBidPrice: bidPrice,
      tradeType: "BUY",
    })
  }

  const handleCloseSellTrades = () => {
    if (activeTrades?.data?.result?.filter((item: any) => item?.trade_type === "SELL")?.length <= 0) {
      toast({
        variant: "destructive",
        description: "No active sell trades found!",
      })
      setOpenAction(false)
      return
    }
    const tMessage = {
      id: Date.now(),
      message: "Your all sell trades have been closed!",
      attachments: [],
      attachImages: [],
      conversationId: conversationId
    }
    setTempMessage(tMessage);
    closeAllTrades({
      userId,
      token,
      vendorId,
      liveAskPrice: askPrice,
      liveBidPrice: bidPrice,
      tradeType: "SELL",
    })
  }

  const handleSendStatement = (pdfFile: File) => {
    if (pdfFile) {
      const newMessage = {
        id: Date.now(),
        message: '',
        attachments: [pdfFile],
        attachImages: [],
        conversationId: conversationId,
      };
      onSendMessage(newMessage);
      handleSeenEvent();
    }
  };

  const captureAndSendScreenshot = async () => {
    if (screenshotRef.current) {
      try {
        setIsScreenshotMode(true);
        // Wait for the next render cycle to apply the screenshot styles
        await new Promise(resolve => setTimeout(resolve, 0));

        const canvas = await html2canvas(screenshotRef.current);
        const imageData = canvas.toDataURL('image/png');

        const response = await fetch(imageData);
        const blob = await response.blob();
        const screenshotFile = new File([blob], 'screenshot.png', { type: 'image/png' });

        const newMessage = {
          id: Date.now(),
          message: '',
          attachments: [],
          attachImages: [screenshotFile],
          conversationId: conversationId,
        };

        onSendMessage(newMessage);
        handleSeenEvent();

        toast({
          variant: "default",
          className: "bg-green-600 text-white hover:bg-green-700",
          description: "Screenshot sent successfully!",
        });
      } catch (error) {
        console.error('Error capturing or sending screenshot:', error);
        toast({
          variant: "destructive",
          description: "Failed to capture or send screenshot. Please try again.",
        });
      } finally {
        setIsScreenshotMode(false);
      }
    }
  };

  // View active positions state and functions here
  const [buyQty, setBuyQty] = useState("0.00");
  const [sellQty, setSellQty] = useState("0.00");
  const [netQty, setNetQty] = useState("0.00");
  const [position, setPosition] = useState("Buy Position");

  const [openActivePositionPopup, setOpenActivePositionPopup] = useState(false);
  const [selectedMetalType, setSelectedMetalType] = useState('ttb');

  const [autoStatementStatus, setAutoStatementStatus] = useState(false);

  const handleViewActivePosition = () => {
    setOpenActivePositionPopup(true);
    setOpenAction(false);
  }
  const closeActivePositionPopup = () => {
    setOpenActivePositionPopup(false);
    setSelectedMetalType('ttb'); // for reset value after close the popup
    refetchActiveTrades(); // for reset value after close the popup
  }

  const { data: activeTrades, refetch: refetchActiveTrades } = GetActiveTrades({
    userId, vendorId, token, conversationId
  });

  useEffect(() => {
    const trades = activeTrades?.data?.result;
    if (trades) {
      const totalBuyQty = trades?.filter((item: any) => item?.trade_type === "BUY").reduce((acc: number, item: any) => acc + item?.quantity, 0);
      const totalSellQty = trades?.filter((item: any) => item?.trade_type === "SELL").reduce((acc: number, item: any) => acc + item?.quantity, 0);
      const totalNetQty = totalBuyQty - totalSellQty;
      const pos = totalNetQty == 0 ? "Equal" : totalNetQty > 0 ? "Buy Position" : "Sell Position";
      setPosition(pos);

      if (selectedMetalType === "ttb") {
        setBuyQty(totalBuyQty?.toFixed(2));
        setSellQty(totalSellQty?.toFixed(2));
        setNetQty(totalNetQty.toFixed(2));
      }
      else if (selectedMetalType === "kilobar") {
        setBuyQty(((totalBuyQty * 116.52) / 1000).toFixed(2));
        setSellQty(((totalSellQty * 116.52) / 1000).toFixed(2));
        setNetQty(((totalNetQty * 116.52) / 1000).toFixed(2));
      }
      else if (selectedMetalType === "gram") {
        setBuyQty((totalBuyQty * 116.52).toFixed(2));
        setSellQty((totalSellQty * 116.52).toFixed(2));
        setNetQty((totalNetQty * 116.52).toFixed(2));
      }
      else if (selectedMetalType === "ounce") {
        setBuyQty(((totalBuyQty * 116.52) / 31.1).toFixed(2));
        setSellQty(((totalSellQty * 116.52) / 31.1).toFixed(2));
        setNetQty(((totalNetQty * 116.52) / 31.1).toFixed(2));
      }
    }
  }, [activeTrades, selectedMetalType, conversationId]);

  const { data: logs, refetch: refetchLogs } = GetLogs({
    userId, vendorId, token, conversationId
  });

  useEffect(() => {
    if (logs) {
      setLogItems(logs?.data);
    }
  }, [logs]);

  const qtyUnit = () => {
    if (selectedMetalType === "ttb") {
      return "TTB";
    }
    else if (selectedMetalType === "kilobar") {
      return "KG";
    }
    else if (selectedMetalType === "gram") {
      return "GM";
    }
    else if (selectedMetalType === "ounce") {
      return "OZ";
    }
  }

  const handleMetalType = (type: string) => {
    const trades = activeTrades?.data?.result;
    if (trades?.length > 0) {
      const totalBuyQty = trades?.filter((item: any) => item?.trade_type === "BUY").reduce((acc: number, item: any) => acc + item?.quantity, 0);
      const totalSellQty = trades?.filter((item: any) => item?.trade_type === "SELL").reduce((acc: number, item: any) => acc + item?.quantity, 0);
      const totalNetQty = totalBuyQty - totalSellQty;
      const pos = totalNetQty > 0 ? "Buy Position" : "Sell Position";
      setPosition(pos);

      if (type === "ttb") {
        setBuyQty(totalBuyQty?.toFixed(2));
        setSellQty(totalSellQty?.toFixed(2));
        setNetQty(totalNetQty.toFixed(2));
      }
      else if (type === "kilobar") {
        setBuyQty(((totalBuyQty * 116.52) / 1000).toFixed(2));
        setSellQty(((totalSellQty * 116.52) / 1000).toFixed(2));
        setNetQty(((totalNetQty * 116.52) / 1000).toFixed(2));
      }
      else if (type === "gram") {
        setBuyQty((totalBuyQty * 116.52).toFixed(2));
        setSellQty((totalSellQty * 116.52).toFixed(2));
        setNetQty((totalNetQty * 116.52).toFixed(2));
      }
      else if (type === "ounce") {
        setBuyQty(((totalBuyQty * 116.52) / 31.1).toFixed(2));
        setSellQty(((totalSellQty * 116.52) / 31.1).toFixed(2));
        setNetQty(((totalNetQty * 116.52) / 31.1).toFixed(2));
      }
    }
    setSelectedMetalType(type);
  }

  const { mutate, isPending: isLoadingStatus } = useAutoStatement({
    onSuccess: (data) => {
      if (data?.status) {
        setAutoStatementStatus(data?.data?.autoStatement);
        // console.log("Auto statement data:", data)
        // toast({
        //   variant: "default",
        //   className: "bg-green-600 text-white hover:bg-green-700",
        //   description: data?.data?.autoStatement ? "Auto statement acivated!" : "Auto statement deactivated!",
        // })
      }
      else {
        toast({
          variant: "destructive",
          className: "bg-red-600 text-white hover:bg-red-700",
          description: data?.message,
        })
      }
    },
    onError: (error) => {
      console.log("Auto statement error:", error)
    },
  });

  const handleAutoStatement = (action: string) => {
    mutate({
      userId, vendorId, token, action
    })
  }

  useEffect(() => {
    if (userId > 0 && vendorId > 0) {
      mutate({
        userId, vendorId, token
      })
    }
  }, [userId, vendorId, token, mutate])

  const handleViewLog = () => {
    setOpenLog(true);
  }

  const scrollToBottom = () => {
    if (sidebarRef.current) {
      sidebarRef.current.scrollTo({
        top: sidebarRef.current.scrollHeight,
        behavior: 'smooth'
      })
    }
    setIsAtBottom(true)
  }

  const scrollToTop = () => {
    if (sidebarRef.current) {
      sidebarRef.current.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
    setIsAtBottom(false)
  }

  useEffect(() => {
    const sidebar = sidebarRef.current
    if (!sidebar) return

    const handleScroll = () => {
      const scrolledToBottom = sidebar.scrollHeight - sidebar.scrollTop <= sidebar.clientHeight + 1
      setIsAtBottom(scrolledToBottom)
    }

    sidebar.addEventListener('scroll', handleScroll)
    return () => sidebar.removeEventListener('scroll', handleScroll)
  }, [])

  const LogTabs = [
    {
      value: "transactions",
      label: "Transactions",
      content: ({ inputRef }: any) => (
        <div className="h-[375px] overflow-y-scroll scrollbar-thin">
          <div className="flex flex-col gap-2 pb-6 px-4">
            <div className="flex items-center justify-between">
              <p className="text-[16px]">Deposit: {logItems?.total_deposit?.toFixed(2)} (AED)</p>
              <p className="text-[16px]">Withdraw: {logItems?.total_withdraw?.toFixed(2)} (AED)</p>
            </div>
            <div className="flex items-center justify-between">
              <p className="text-[16px]">Profit: {logItems?.total_profit?.toFixed(2)} (AED)</p>
              <p className="text-[16px]">Loss: {logItems?.total_loss?.toFixed(2)} (AED)</p>
            </div>
          </div>
          <ul className="space-y-2">
            {logItems?.transactions?.slice(0, 5)?.map((transaction) => (
              <li key={transaction.id} className="flex items-start space-x-2 bg-accent/40 p-2 rounded border-l border-green-400">
                <div className="flex-1">
                  <div className="text-sm text-gray-500">{formatDate(transaction.updatedAt)}</div>
                  <div className="mt-2 flex items-center space-x-3">
                    <Avatar className="w-8 h-8">
                      <AvatarImage src="/no-image" alt="Dev" />
                      <AvatarFallback>{transaction.actionUser.name?.charAt(0)}</AvatarFallback>
                    </Avatar>
                    <div className="flex-1">
                      <p className="text-sm font-medium">{transaction.actionUser.name}</p>
                      <p className="text-sm text-gray-500">
                        Amount <span className="font-semibold">{transaction.amount.toFixed(2)} AED</span>{' '} has been{' '}
                        {transaction.type} to {transaction.subType} balance.
                      </p>
                    </div>
                    <span
                      className={`text-sm font-medium ${transaction.type === 'profit' ? 'text-green-600' : 'text-red-600'
                        }`}
                    >
                      {transaction.type.toUpperCase()}
                    </span>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      ),
      footerButton: ""
    },
    {
      value: "completedtrades",
      label: "Completed Trades",
      content: ({ inputRef }: any) => (
        <div className="h-[375px] overflow-y-scroll scrollbar-thin">
          <div className="flex flex-col gap-2 pb-6 px-4">
            <div className="flex items-center justify-between">
              <p className="text-[16px]">Deposit: {logItems?.total_deposit?.toFixed(2)} (AED)</p>
              <p className="text-[16px]">Withdraw: {logItems?.total_withdraw?.toFixed(2)} (AED)</p>
            </div>
            <div className="flex items-center justify-between">
              <p className="text-[16px]">Profit: {logItems?.total_profit?.toFixed(2)} (AED)</p>
              <p className="text-[16px]">Loss: {logItems?.total_loss?.toFixed(2)} (AED)</p>
            </div>
          </div>
          <ul className="space-y-2">
            {logItems?.completedTrades?.slice(0, 5)?.map((comp) => (
              <li key={comp.id} className="flex items-start space-x-2 bg-accent/40 p-2 rounded border-l border-green-400">
                <div className="flex-1">
                  <div className="text-sm text-gray-500">{formatDate(comp?.updatedAt)}</div>
                  <div className="mt-2 flex items-end justify-between space-x-3">
                    <div className="flex items-center space-x-3">
                      <Avatar className="w-8 h-8">
                        <AvatarImage src="/no-image" alt="Dev" />
                        <AvatarFallback>{comp?.account?.actionUser?.name?.charAt(0)}</AvatarFallback>
                      </Avatar>
                      <div className="flex-1">
                        <p className="text-sm font-medium">{comp?.account?.actionUser?.name}</p>
                        <p className="text-sm text-gray-500">
                          Made {comp.account.type} of{' '}
                          <span className="font-semibold">{comp.account.amount.toFixed(2)} AED</span>{' '}
                          (includes a <span className="font-semibold">{comp.account.charge.toFixed(2)} AED</span> charge)
                        </p>
                      </div>
                    </div>
                    <span
                      className={`text-sm font-medium ${comp.account.type === 'profit' ? 'text-green-600' : 'text-red-600'} ml-auto`}
                    >
                      {comp.account.type.toUpperCase()}
                    </span>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      ),
      footerButton: ""
    },
  ];

  if (!sidebar) return null
  return (
    <aside
      ref={sidebarRef}
      className={cn(
        "z-20 h-full -translate-x-full border-l transition-[width] duration-300 ease-in-out lg:translate-x-0 overflow-y-auto",
        sidebar?.isOpen === false ? "hidden" : "w-96"
      )}
    >
      {
        isUserAvailable ?
          <div className="scrollbar-thin relative flex h-full flex-col overflow-y-auto px-3 py-4">
            <div className="flex justify-between">
              <div className="flex items-center gap-4 text-sm">
                <CustomAvatar src={selectedUser?.attributes?.profile_photo?.[0]} alt={selectedUser?.attributes?.profile_photo?.[0]} icon={null} size={10} />
                <div>
                  <h3 className="font-medium">{userName}</h3>
                  <span className="text-muted-foreground">@{vendorInitId}{uuId}</span>
                </div>
              </div>

              <Popover
                open={openAction}
                onOpenChange={(isOpen) => setOpenAction(isOpen)}
              >
                <PopoverTrigger asChild>
                  <div>
                    <BsThreeDotsVertical size="18" className="cursor-pointer" />
                  </div>
                </PopoverTrigger>
                <PopoverContent className="mr-2 max-w-[240px]">
                  <ActionPopContent
                    handleCloseAllTrades={handleCloseAllTrades}
                    handleCloseBuyTrades={handleCloseBuyTrades}
                    handleCloseSellTrades={handleCloseSellTrades}
                    handleViewLog={handleViewLog}
                    handleAutoStatement={handleAutoStatement}
                    autoStatementStatus={autoStatementStatus}
                    isLoadingStatus={isLoadingStatus}
                  />
                </PopoverContent>
              </Popover>
            </div>

            <div className="mt-6 flex justify-between gap-4 text-sm">
              <div className="text-left">
                <h3 className="text-muted-foreground">Account Balance(AED)</h3>
                <span className="text-base font-medium">{accountBalance ? (accountBalance * AEDConversionRate).toFixed(2) : 0}</span>
              </div>
              <div className="text-right">
                <h3 className="text-muted-foreground">Margin Limit($)</h3>
                <span className={`${((position == 'Buy Position' ? (askPrice - marginLimit) : (marginLimit - bidPrice)) < 20) ? 'bg-red-400' : 'bg-green-700'} px-2 text-base font-medium`}>{marginLimit}</span>
              </div>
            </div>

            <div className="mt-2 flex justify-between gap-4 text-sm">
              <div className="text-left">
                <h3 className="text-muted-foreground">Profit / Loss(AED)</h3>
                <span className={`text-base font-medium ${parseInt(profitLoss) < 0 ? 'text-red-400' : 'text-green-400'}`}>{profitLoss}</span>
              </div>
              <div className="text-right">
                <h3 className="text-muted-foreground">Net Balance(AED)</h3>
                <span className="text-base font-medium">{netBalance?.toFixed(2)}</span>
              </div>
            </div>

            <Separator className="my-2" />

            <div className="mt-2 flex justify-between gap-4 ">
              <div className="text-left">
                <h3 className="text-muted-foreground">Sell (Bid)</h3>
                <span className="text-base font-medium text-red-400">{bidPrice?.toFixed(2)}</span>
              </div>
              <div>
                <h3 className="text-muted-foreground">Buy (Ask)</h3>
                <span className="text-base font-medium text-green-400">{askPrice?.toFixed(2)}</span>
              </div>
              <div>
                <h3 className="flex items-center gap-1 text-muted-foreground">High <IoMdArrowRoundUp className="text-green-400" /></h3>
                <span className="text-base font-medium">{highPrice.toFixed(2)}</span>
              </div>
              <div>
                <h3 className="flex items-center gap-1 text-muted-foreground">Low <IoMdArrowRoundDown className="text-red-600" /></h3>
                <span className="text-base font-medium">{lowPrice.toFixed(2)}</span>
              </div>
            </div>
            <Separator className="my-3" />
            <Actions
              selectedUser={userId}
              selectedVendor={vendorId}
              onSuccessCallback={refetchQuery}
              onSuccessPendingCallback={refetchPendingQuery}
              onSendMessage={onSendMessage}
              conversationId={conversationId}
              askRate={askPrice}
              bidRate={bidPrice}
              customerLastMessages={customerLastMessages}
              autoStatementStatus={autoStatementStatus}
              statementRefetch={statementRefetch}
              statements={statementData?.data}
              handleSendStatement={handleSendStatement}
              userRefetch={userRefetch}
              handleSeenEvent={handleSeenEvent}
            />
            <div className="mt-2 flex justify-evenly text-sm">
              <TradingPosition content={
                <div>
                  <Select value={selectedMetalType} onValueChange={(value) => handleMetalType(value)}>
                    <SelectTrigger>
                      <SelectValue
                        placeholder="Select role.."
                        className="text-[#7F8EA3]"
                      />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectGroup>
                        {
                          metalTypes.map((item) => (
                            <SelectItem key={item.value} value={item.value}>
                              {item.label}
                            </SelectItem>
                          ))
                        }
                      </SelectGroup>
                    </SelectContent>
                  </Select>

                  <div className="grid gap-4 mt-4 pl-2">
                    <div className="grid gap-2">
                      {Number(buyQty) >= Number(sellQty) ? (
                        <>
                          <div className="grid grid-cols-2 items-center gap-4">
                            <span className="text-sm text-primary">Buy Quantity:</span>
                            <span className="text-sm font-medium text-right text-primary">{buyQty} {qtyUnit()}</span>
                          </div>
                          <div className="grid grid-cols-2 items-center gap-4">
                            <span className="text-sm text-primary">Sell Quantity:</span>
                            <span className="text-sm font-medium text-right text-primary">{sellQty} {qtyUnit()}</span>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="grid grid-cols-2 items-center gap-4">
                            <span className="text-sm text-primary">Sell Quantity:</span>
                            <span className="text-sm font-medium text-right text-primary">{sellQty} {qtyUnit()}</span>
                          </div>
                          <div className="grid grid-cols-2 items-center gap-4">
                            <span className="text-sm text-primary">Buy Quantity:</span>
                            <span className="text-sm font-medium text-right text-primary">{buyQty} {qtyUnit()}</span>
                          </div>
                        </>
                      )}

                      <div className="grid grid-cols-2 items-center gap-4">
                        <span className="text-sm text-primary">Net Quantity:</span>
                        <span className="text-sm font-medium text-right text-primary">{netQty} {qtyUnit()}</span>
                      </div>
                      <div className="grid grid-cols-1 justify-center items-center gap-4 mt-2">
                        {/* <span className="text-sm text-primary">Position:</span> */}
                        <span className={`font-medium ${position === "Buy Position" ? "text-green-400" : "text-red-400"} text-center`}>({position})</span>
                      </div>
                    </div>
                  </div>
                </div>
              } />
              <Button variant="outline" onClick={handleViewLog}>
                View Logs
              </Button>
            </div>
            <Separator className="my-3" />
            <div
              ref={screenshotRef}
              className={cn(isScreenshotMode && (theme === "dark" ? "bg-[#030711] p-4" : "bg-[#ffffff] p-4"))}
            >
              {
                isScreenshotMode && (
                  <>
                    <div className="flex justify-between">
                      <div className="flex items-center gap-4 text-sm">
                        <CustomAvatar src={selectedUser?.attributes?.profile_photo?.[0]} alt={selectedUser?.attributes?.profile_photo?.[0]} icon={null} size={10} />
                        <div>
                          <h3 className="font-medium">{userName}</h3>
                          <span className="text-muted-foreground">@{vendorInitId}{uuId}</span>
                        </div>
                      </div>
                    </div>
                    <div className="mt-4 flex justify-between gap-4 text-sm">
                      <div className="text-left">
                        <h3 className="text-muted-foreground">Account Balance(AED)</h3>
                        <span className="text-base font-medium">{accountBalance ? (accountBalance * AEDConversionRate).toFixed(2) : 0}</span>
                      </div>
                      <div className="text-right">
                        <h3 className="text-muted-foreground">Margin Limit($)</h3>
                        <span className={`${((position == 'Buy Position' ? (askPrice - marginLimit) : (marginLimit - bidPrice)) < 20) ? 'text-red-400' : 'text-green-400'} px-2 text-base font-medium`}>{marginLimit}</span>
                      </div>
                    </div>

                    <div className="mt-2 flex justify-between gap-4 text-sm">
                      <div className="text-left">
                        <h3 className="text-muted-foreground">Profit / Loss(AED)</h3>
                        <span className={`text-base font-medium ${parseInt(profitLoss) < 0 ? 'text-red-400' : 'text-green-400'}`}>{profitLoss}</span>
                      </div>
                      <div className="text-right">
                        <h3 className="text-muted-foreground">Net Balance(AED)</h3>
                        <span className="text-base font-medium">{netBalance?.toFixed(2)}</span>
                      </div>
                    </div>

                    <Separator className="my-2" />

                    <div className="mt-2 flex justify-between gap-4 ">
                      <div className="text-left">
                        <h3 className="text-muted-foreground">Sell (Bid)</h3>
                        <span className="text-base font-medium text-red-400">{bidPrice?.toFixed(2)}</span>
                      </div>
                      <div>
                        <h3 className="text-muted-foreground">Buy (Ask)</h3>
                        <span className="text-base font-medium text-green-400">{askPrice?.toFixed(2)}</span>
                      </div>
                      <div>
                        <h3 className="flex items-center gap-1 text-muted-foreground">High</h3>
                        <span className="text-base font-medium">{highPrice.toFixed(2)}</span>
                      </div>
                      <div>
                        <h3 className="flex items-center gap-1 text-muted-foreground">Low</h3>
                        <span className="text-base font-medium">{lowPrice.toFixed(2)}</span>
                      </div>
                    </div>
                  </>
                )
              }
              <ActiveTrades
                refetchQuery={(refetch: any) => setRefetchQuery(() => refetch)}
                conversationId={conversationId}
                onSendMessage={onSendMessage}
                vendorId={vendorId}
                userId={userId}
                askRate={askPrice}
                bidRate={bidPrice}
                onTradeFromMessage={onTradeFromMessage}
                isScreenshotMode={isScreenshotMode}
                customerLastMessages={customerLastMessages}
                autoStatementStatus={autoStatementStatus}
                statementRefetch={statementRefetch}
                handleSendStatement={handleSendStatement}
                userRefetch={userRefetch}
                handleSeenEvent={handleSeenEvent}
              />
              <PendingTrades
                refetchQuery={(refetch: any) => setRefetchPendingQuery(() => refetch)}
                vendorId={vendorId}
                userId={userId}
                conversationId={conversationId}
                onSendMessage={onSendMessage}
                isScreenshotMode={isScreenshotMode}
              />
            </div>

            <ActivePDFGenerator statements={statements} statementRefetch={statementRefetch} handleMessage={handleSendStatement} />
            <PDFGenerator statements={statements} statementRefetch={statementRefetch} handleMessage={handleSendStatement} />

            <Button onClick={captureAndSendScreenshot} size="lg" variant="outline" className="py-4 mt-4">
              Send Screenshot
            </Button>
          </div>
          :
          <div className="flex h-full flex-col items-center justify-center">
            <NotFoundAnimation />
            <h1 className="text-md mt-[-40px] font-bold">No account found!</h1>
          </div>
      }

      <DynamicDialog
        open={openLog}
        close={() => setOpenLog(false)}
        label={<span className="flex gap-2">Trading/Transaction Logs</span>}
        description="Log details"
        content={<DynamicTabs tabs={LogTabs} customWidth={650} />}
        handleSubmit=""
        customWidth={650}
      />

      {/* <Button
        onClick={isAtBottom ? scrollToTop : scrollToBottom}
        variant="outline"
        className="fixed bottom-4 p-2 right-4 rounded-full shadow-lg transition-colors"
        title={isAtBottom ? "Scroll to top" : "Scroll to bottom"}
      >
        {isAtBottom ? <ArrowUp className="h-4 w-4" /> : <ArrowDown className="h-4 w-4" />}
      </Button> */}
    </aside>
  )
}

const ActionPopContent = React.memo(({ handleCloseAllTrades, handleCloseBuyTrades, handleCloseSellTrades, handleViewLog, handleAutoStatement, autoStatementStatus, isLoadingStatus }: any) => {
  return (
    <div>
      {/* <Button
        variant="ghost"
        className="flex w-full flex-row justify-between border-none"
        onClick={handleViewActivePosition}
      >
        <Label className="cursor-pointer">View Active Position</Label>
        <BsGraphUpArrow size={12} />
      </Button> */}
      <Button
        variant="ghost"
        disabled
        className="flex w-full flex-row justify-between border-none"
      >
        <Label>Calculator</Label>
        <FaCalculator size={14} />
      </Button>
      {/* <Button
        variant="ghost"
        className="flex w-full flex-row justify-between border-none"
        onClick={handleViewLog}
      >
        <Label>Logs</Label>
        <TbReportMoney size={16} />
      </Button> */}
      <Button
        variant="ghost"
        className="flex w-full flex-row justify-between border-none"
        onClick={handleCloseAllTrades}
      >
        <Label className="cursor-pointer">Close All Trades</Label>
        <VscCloseAll size={14} />
      </Button>
      <Button
        variant="ghost"
        className="flex w-full flex-row justify-between border-none"
        onClick={handleCloseBuyTrades}
      >
        <Label className="cursor-pointer">Close All Buy Trades</Label>
        <VscCloseAll size={14} />
      </Button>
      <Button
        variant="ghost"
        className="flex w-full flex-row justify-between border-none"
        onClick={handleCloseSellTrades}
      >
        <Label className="cursor-pointer">Close All Sell Trades</Label>
        <VscCloseAll size={14} />
      </Button>
      <Button
        variant="ghost"
        className="flex w-full flex-row justify-between border-none hover:border-none hover:bg-transparent"
      >
        <Label className="cursor-pointer">{isLoadingStatus ? "Processing..." : "Auto Statement"}</Label>
        <Switch
          checked={autoStatementStatus}
          onCheckedChange={(checked) => {
            handleAutoStatement(checked ? 'enable' : 'disable')
          }} />
      </Button>
    </div>
  )
})