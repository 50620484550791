import Keycloak, {
  KeycloakAdapter,
  KeycloakLogoutOptions,
  KeycloakRegisterOptions,
} from "keycloak-js"

const keycloakConfig = {
  url: "https://accounts.dreamemirates.com",
  realm: "dreamemirates",
  clientId: "onchat-production",
}

let keycloak: Keycloak

if (typeof window !== "undefined") {
  keycloak = new Keycloak(keycloakConfig)
}

let isInitialized = false

export const initKeycloak = async () => {
  if (isInitialized && keycloak)
    return Promise.reject(new Error("Keycloak is already initialized"))

  let isAuthenticated = false

  const checkKeycloakDatas: {
    token: string
    refreshToken: string
    idToken: string
  } | null = localStorage.getItem("keycloakDatas")
    ? JSON.parse(localStorage.getItem("keycloakDatas") ?? "")
    : null

  let initOptions = {}

  if (!checkKeycloakDatas) {
    initOptions = {
      onLoad: "check-sso",
      silentCheckSsoRedirectUri: `${location.origin}/silent-check-sso.html`,
    }
  } else {
    initOptions = {
      token: checkKeycloakDatas.token,
      refreshToken: checkKeycloakDatas.refreshToken,
      idToken: checkKeycloakDatas.idToken,
    }
  }

  // console.log("initOptions", initOptions)

  isInitialized = true // Set the flag to true

  await keycloak
    .init(initOptions)
    .then(async (authenticated) => {
      if (!authenticated) {

        console.log("Failed to authenticate with Keycloak. Force login Or UpdateToken...")

        checkKeycloakDatas && localStorage.removeItem("keycloakDatas")

        if (!checkKeycloakDatas) {
          console.log("Redirect Login...");
          await keycloak.login({
            scope: "openid profile email phone",
          })
        }else if(keycloak && !keycloak.isTokenExpired()){
          console.log("Need Reload...");
          // window.location.reload()
          await keycloak.updateToken(2*3600)
        }else{
          console.log("Update Token... 2h");
          await updateToken()
        }
      }

      console.log("Writing keycloak datas to localStorage...");
      
      localStorage.setItem(
        "keycloakDatas",
        JSON.stringify({
          token: keycloak.token,
          refreshToken: keycloak.refreshToken,
          idToken: keycloak.idToken,
        })
      )

      isAuthenticated = authenticated
    })
    .catch((err) => {
      isInitialized = false
      console.log("Failed to initialize Keycloak", err)
      logout()
      throw err
    })

  return new Promise((resolve, reject) => {
    const checkInterval = 100
    const timeout = 10000
    let elapsed = 0

    const intervalId = setInterval(() => {
      if (isAuthenticated) {
        clearInterval(intervalId)
        return resolve(isAuthenticated)
      }

      elapsed += checkInterval

      if (elapsed >= timeout) {
        clearInterval(intervalId)
        return reject(isAuthenticated)
      }
    }, checkInterval)
  })
}

export const logout = () => {
  if (keycloak) {
    localStorage.removeItem("keycloakDatas")
    keycloak.logout()
  }
}

const updateToken = async () => {
  // console.log("Calling Update Token");
  
  if (keycloak.isTokenExpired()) {
    try {
      console.log("Token is expired and refreshing...");
      await keycloak.updateToken(30)
      localStorage.setItem(
        "keycloakDatas",
        JSON.stringify({
          token: keycloak.token,
          refreshToken: keycloak.refreshToken,
          idToken: keycloak.idToken,
        })
      )
    } catch (error) {
      console.error("Failed to refresh the token", error)
      keycloak.logout()
      return null
    }
  }
  return null
}

export const getToken = () => {
  //console.log("Calling Get Token");
  if (keycloak) {
    updateToken()
    return keycloak.token
  }
  return null
}

export { keycloak }
