"use client"
import React, { useEffect, useState } from "react"
import { ChevronDown, ChevronUp, Loader2 } from "lucide-react"

import { Button } from "../ui/button"
import DynamicDialog from "../custom/dynamic-dialog"
import { Input } from "../ui/input"
import { Label } from "../ui/label"
import AutocompleteDropdown from "../custom/autocomplete-dropdown"
import DynamicTabs from "../custom/dynamic-tabs"
import { useToast } from "../ui/use-toast"
import { useOpenPendingTrades } from "@/api_store/mutations/pendingTrades/useOpenPendingTrades"
import { useOpenBuyTrades } from "@/api_store/mutations/buyTrades/useOpenBuyTrade"
import { useKeycloak } from "@/auth/provider/KeycloakProvider"
import { formatDateTime } from "./chat-list"
import { useMarketPrice } from "@/api_store/queries/marketPrice/useMarketPrice"
import { MdPendingActions } from "react-icons/md"
import { generateAndSendPDF } from "../custom/pdf-generator/generateAndSendPDF"
import AutocompleteDropdownNew from "../custom/autocomplete-dropdown-new"

type Props = {
  selectedUser: number,
  selectedVendor: number,
  onSuccessCallback: () => void,
  onSuccessPendingCallback: () => void,
  onSendMessage: (message: any) => void;
  conversationId: any;
  askRate: number;
  bidRate: number;
  customerLastMessages: any;
  autoStatementStatus: boolean;
  statementRefetch: any;
  statements: any;
  handleSendStatement: (file: any) => void;
  userRefetch: () => void
  handleSeenEvent: () => void
}

const Actions = ({
  selectedUser,
  selectedVendor,
  onSuccessCallback,
  onSuccessPendingCallback,
  onSendMessage,
  conversationId,
  askRate,
  bidRate,
  customerLastMessages,
  autoStatementStatus,
  statementRefetch,
  statements,
  handleSendStatement,
  userRefetch,
  handleSeenEvent
}: Props) => {
  const options = [
    { value: "TTB", label: "TTB (116.52 gm)" },
    { value: "KILOBAR", label: "KILOBAR (1000 gm)" },
    { value: "GRAM", label: "GRAM (1 gm)" },
    { value: "OUNCE", label: "OUNCE (28.35 gm)" },
  ]

  const { tradeToken: token } = useKeycloak();
  const { toast } = useToast()

  const [metalTypes, setMetalTypes] = useState(options);
  const [openBuy, setOpenBuy] = useState(false);
  const [openSell, setOpenSell] = useState(false);
  const [openPending, setOpenPending] = useState(false);

  const [selectedMessage, setSelectedMessage] = useState("");
  const [userMessages, setUserMessages] = useState<any[]>([]);

  const closeBuy = () => {
    setOpenBuy(false);
    resetMessageSelection();
    resetVirtual()
  };
  const closeSell = () => {
    setOpenSell(false);
    resetMessageSelection();
    resetVirtual()
  };
  const closePending = () => setOpenPending(false);

  // Pending Trade states
  const [pendingBuyQuantity, setPendingBuyQuantity] = useState("");
  const [pendingSellQuantity, setPendingSellQuantity] = useState("");

  const [pendingBuyRate, setPendingBuyRate] = useState("");
  const [pendingSellRate, setPendingSellRate] = useState("");

  const [pendingBuyMetalType, setPendingBuyMetalType] = useState("TTB");
  const [pendingSellMetalType, setPendingSellMetalType] = useState("TTB");

  // Virtual Buy trade states
  const [virtualBuyQuantity, setVirtualBuyQuantity] = useState("");
  const [virtualBuyMetalType, setVirtualBuyMetalType] = useState("TTB");

  // Virtual Sell trade states
  const [virtualSellQuantity, setVirtualSellQuantity] = useState("");
  const [virtualSellMetalType, setVirtualSellMetalType] = useState("TTB");

  //Market Price
  const [customerAskrate, setCustomerAskRate] = useState(0);
  const [customerBidrate, setCustomerBidRate] = useState(0);

  // Function to open a dialog if none are already open
  const openDialog = (dialogSetter: any) => {
    if (!openBuy && !openSell && !openPending) {
      dialogSetter(true);
    }
  };

  // Add event listener for Alt+B, Alt+S, and Alt+P to open dialogs
  useEffect(() => {
    const handleKeyDown = (e: any) => {
      if (e.altKey) {
        switch (e.key.toLowerCase()) {
          case "b":
            openDialog(setOpenBuy);
            break;
          case "s":
            openDialog(setOpenSell);
            break;
          case "p":
            openDialog(setOpenPending);
            break;
          default:
            break;
        }
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [openBuy, openSell, openPending, openDialog]);

  const resetPending = () => {
    setPendingBuyQuantity("");
    setPendingBuyRate("");
    setPendingBuyMetalType("TTB");

    setPendingSellQuantity("");
    setPendingSellRate("");
    setPendingSellMetalType("TTB");
  }

  const resetVirtual = () => {
    setVirtualBuyQuantity("");
    setVirtualBuyMetalType("TTB");
    setVirtualSellQuantity("");
    setVirtualSellMetalType("TTB");
  }

  const resetMessageSelection = () => {
    setSelectedMessage("");
    setCustomerAskRate(0);
    setCustomerBidRate(0);
  }

  function convertToUnixTimestamp(isoString: string) {
    const date = new Date(isoString);
    return Math.floor(date.getTime() / 1000);
  }

  useEffect(() => {
    if (customerLastMessages?.length > 0) {
      const msgsOptions = customerLastMessages?.map((item: any) => ({
        id: item?.id,
        value: convertToUnixTimestamp(item?.sentTime).toString(),
        label: (item?.media?.length > 0 && item?.message?.length <= 0) ? `Media content.. (${formatDateTime(item?.sentTime)})` : `${item?.message} (${formatDateTime(item?.sentTime)})`
      }));
      setUserMessages(msgsOptions);
      // console.log(msgsOptions);
    }
  }, [customerLastMessages])

  // API call here
  const { data, isPending: isLoading, refetch } = useMarketPrice(selectedMessage);

  useEffect(() => {
    if (data) {
      // console.log("Market price data:", data);
      setCustomerAskRate(data?.data?.ask);
      setCustomerBidRate(data?.data?.bid);
    }
    else{
      setCustomerAskRate(0);
      setCustomerBidRate(0);
    }
  }, [data, selectedMessage])

  const { mutate, isPending: isLoadingPending } = useOpenPendingTrades({
    onSuccess: (data) => {
      if (data?.status) {
        const message = {
          id: Date.now(),
          message: `Your pending trade #${data?.data?.tradeId} has been submitted successfully!\n\nTrade Type: ${data?.data?.tradeType}\nQuantity: ${data?.data?.quantity} ${data?.data?.metalType}\nTrigger Rate: ${data?.data?.pendingTradeRate}`,
          attachments: [],  // If needed, you can add attachments here
          attachImages: [],  // If needed, you can add image attachments here
          conversationId: conversationId
        };
        onSendMessage(message);
        handleSeenEvent();
        userRefetch();
        toast({
          variant: "default",
          className: "bg-green-600 text-white hover:bg-green-700",
          description: "Pending trade created successfully!",
        })
        // refetch()
        onSuccessPendingCallback();
        closePending()
      }
      resetPending()
    },
    onError: (error) => {
      toast({
        variant: "destructive",
        description: "Server error. Please contact support!",
      })
      console.log(error)
    },
  });

  const { mutate: mutateBuySell, isPending: isLoadingBuySell } = useOpenBuyTrades({
    onSuccess: async (data) => {
      if (data?.status) {
        const message = (selectedMessage?.length > 0) ? {
          id: Date.now(),
          message: `Your trade #${data?.data?.tradeId} has been placed successfully!\n\nTrade Type: ${data?.data?.tradeType}\nRate: ${data?.data?.executedPrice}\nQuantity: ${data?.data?.quantity} ${data?.data?.metalType}`,
          attachments: [],  // If needed, you can add attachments here
          attachImages: [],  // If needed, you can add image attachments here
          conversationId: conversationId,
          contextId: userMessages?.find((a: any) => a.value === selectedMessage)?.id
        }
          : {
            id: Date.now(),
            message: `Your trade #${data?.data?.tradeId} has been placed successfully!\n\nTrade Type: ${data?.data?.tradeType}\nRate: ${data?.data?.executedPrice}\nQuantity: ${data?.data?.quantity} ${data?.data?.metalType}`,
            attachments: [],  // If needed, you can add attachments here
            attachImages: [],  // If needed, you can add image attachments here
            conversationId: conversationId
          };
        onSendMessage(message);
        handleSeenEvent();
        userRefetch();
        resetMessageSelection();

        // Refetch statement data and use it for generating PDF
        const { data: latestStatementData } = await statementRefetch();
        if (latestStatementData && autoStatementStatus) {
          await generateAndSendPDF(latestStatementData.data, handleSendStatement);
        }

        toast({
          variant: "default",
          className: "bg-green-600 text-white hover:bg-green-700",
          description: "Trade placed successfully!",
        })
        // refetch()
        onSuccessCallback();
        closeBuy();
        closeSell();
        resetVirtual()
        // console.log("first", data?.data);
      }
      else {
        toast({
          variant: "destructive",
          description: data?.message,
        })
      }
    },
    onError: (error) => {
      if (error?.status === 402) {
        toast({
          variant: "destructive",
          description: "Insufficient balance!",
        })
        return;
      }
      toast({
        variant: "destructive",
        description: "Server error. Please contact support!",
      })
      console.log(error)
    },
  });

  const getMetalWeight = (metalType: string) => {
    switch (metalType) {
      case "TTB":
        return 116.52;
      case "KILOBAR":
        return 1000;
      case "GRAM":
        return 1;
      case "OUNCE":
        return 28.35;
      default:
        return 0;
    }
  }

  const handlePendingTradeSubmit = (tradeType: "BUY" | "SELL") => {
    if (tradeType === "BUY" && (Number(pendingBuyQuantity) <= 0 || Number(pendingBuyRate) <= 0)) {
      toast({
        variant: "destructive",
        description: "Quantity and Rate must be greater than 0!",
      })
      return;
    }
    else if (tradeType === "SELL" && (Number(pendingSellQuantity) <= 0 || Number(pendingSellRate) <= 0)) {
      toast({
        variant: "destructive",
        description: "Quantity and Rate must be greater than 0!",
      })
      return;
    }
    mutate({
      vendorId: selectedVendor,
      userId: selectedUser,
      metalType: tradeType === "BUY" ? pendingBuyMetalType : pendingSellMetalType,
      tradeType,
      baseMetalWeight: tradeType === "BUY" ? getMetalWeight(pendingBuyMetalType) : getMetalWeight(pendingSellMetalType),
      livePrice: tradeType === "BUY" ? Number(askRate) : Number(bidRate),
      pendingTradeRate: tradeType === "BUY" ? Number(pendingBuyRate) : Number(pendingSellRate),
      quantity: tradeType === "BUY" ? Number(pendingBuyQuantity) : Number(pendingSellQuantity),
      token,
    })
  }

  const handleBuyTradeSubmit = (tradeType: "BUY" | "SELL") => {
    if (tradeType === "BUY" && Number(virtualBuyQuantity) <= 0) {
      toast({
        variant: "destructive",
        description: "Quantity must be greater than 0!",
      })
      return;
    }
    else if (tradeType === "SELL" && Number(virtualSellQuantity) <= 0) {
      toast({
        variant: "destructive",
        description: "Quantity must be greater than 0!",
      })
      return;
    }
    mutateBuySell({
      vendorId: selectedVendor,
      userId: selectedUser,
      metalType: tradeType === "BUY" ? virtualBuyMetalType : virtualSellMetalType,
      tradeType,
      baseMetalWeight: tradeType === "BUY" ? getMetalWeight(virtualBuyMetalType) : getMetalWeight(virtualSellMetalType),
      livePrice: tradeType === "BUY" ? (selectedMessage?.length > 0 && customerAskrate > 0) ? Number(customerAskrate) : Number(askRate) : (selectedMessage?.length > 0 && customerBidrate > 0) ? Number(customerBidrate) : Number(bidRate),
      quantity: tradeType === "BUY" ? Number(virtualBuyQuantity) : Number(virtualSellQuantity),
      token,
    })
  }

  const pendingTabs = [
    {
      value: "buy",
      label: "Buy",
      content: ({ inputRef }: any) => (
        <>
          <div className="grid grid-cols-4 items-center gap-4 pt-4">
            <Label>Live Price</Label>
            <h3 className="col-span-3 text-green-400">$ {askRate}</h3>
          </div>
          <div className="grid grid-cols-4 items-center gap-4 pt-6">
            <Label htmlFor="quantity">Quantity</Label>
            <Input ref={inputRef} value={pendingBuyQuantity} onChange={(e) => setPendingBuyQuantity(e.target.value)} className="col-span-3" id="quantity" type="text" autoFocus placeholder="Enter quantity" />
          </div>
          <div className="grid grid-cols-4 items-center gap-4 pt-6">
            <Label htmlFor="rate">Set Rate</Label>
            <Input
              value={pendingBuyRate}
              onChange={(e) => setPendingBuyRate(e.target.value)}
              onKeyDown={(e) => {
                if (openPending && (e.key === "Enter")) {
                  handlePendingTradeSubmit("BUY");
                }
              }}
              className="col-span-3"
              id="rate" type="text"
              placeholder="Enter your expected rate"
            />
          </div>
          <div className="grid grid-cols-4 items-center gap-4 pt-6">
            <Label htmlFor="name" className="text-left">
              Metal Type
            </Label>
            <AutocompleteDropdownNew
              options={metalTypes}
              value={pendingBuyMetalType}
              onChange={setPendingBuyMetalType}
              placeholder="Search metal type"
              dropdownLabel="Select metal type"
              setSearchText={(value) => {
                if (value.length <= 0) {
                  setMetalTypes(options);
                  return;
                }
                const mTypes = metalTypes.filter((mType) => mType.label.toLowerCase().includes(value.toLocaleLowerCase()))
                setMetalTypes(mTypes)
              }}
            />
          </div>
        </>
      ),
      footerButton:
        <Button
          size="lg"
          variant="outline"
          disabled={isLoadingPending}
          onClick={() => handlePendingTradeSubmit("BUY")}
          className="w-full gap-1 border-green-400 hover:bg-green-400/20"
        >
          {isLoadingPending ? <><Loader2 className="w-4 h-4 animate-spin" /> Buy</> : "Buy"}
        </Button>
    },
    {
      value: "sell",
      label: "Sell",
      content: ({ inputRef }: any) => (
        <>
          <div className="grid grid-cols-4 items-center gap-4 pt-4">
            <Label>Live Price</Label>
            <h3 className="col-span-3 text-red-400">$ {bidRate}</h3>
          </div>
          <div className="grid grid-cols-4 items-center gap-4 pt-6">
            <Label htmlFor="quantity">Quantity</Label>
            <Input ref={inputRef} value={pendingSellQuantity} onChange={(e) => setPendingSellQuantity(e.target.value)} className="col-span-3" id="quantity" type="text" autoFocus placeholder="Enter quantity" />
          </div>
          <div className="grid grid-cols-4 items-center gap-4 pt-6">
            <Label htmlFor="rate">Set Rate</Label>
            <Input
              value={pendingSellRate}
              onChange={(e) => setPendingSellRate(e.target.value)}
              onKeyDown={(e) => {
                if (openPending && (e.key === "Enter")) {
                  handlePendingTradeSubmit("SELL");
                }
              }}
              className="col-span-3"
              id="rate"
              type="text"
              placeholder="Enter your expected rate"
            />
          </div>
          <div className="grid grid-cols-4 items-center gap-4 pt-6">
            <Label htmlFor="name" className="text-left">
              Metal Type
            </Label>
            <AutocompleteDropdownNew
              options={metalTypes}
              value={pendingSellMetalType}
              onChange={setPendingSellMetalType}
              placeholder="Search metal type"
              dropdownLabel="Select metal type"
              setSearchText={(value) => {
                if (value.length <= 0) {
                  setMetalTypes(options);
                  return;
                }
                const mTypes = metalTypes.filter((mType) => mType.label.toLowerCase().includes(value.toLocaleLowerCase()))
                setMetalTypes(mTypes)
              }}
            />
          </div>
        </>
      ),
      footerButton:
        <Button
          size="lg"
          variant="outline"
          disabled={isLoadingPending}
          onClick={() => handlePendingTradeSubmit("SELL")}
          className="w-full gap-1 border-red-400 hover:bg-red-400/20"
        >
          {isLoadingPending ? <><Loader2 className="w-4 h-4 animate-spin" /> Sell</> : "Sell"}
        </Button>
    },
  ];

  return (
    <div className="border-b pb-4 text-sm">
      <h3 className="mb-2 text-muted-foreground">Actions</h3>
      <div className="grid grid-cols-3 gap-2">
        <Button
          size="lg"
          variant="outline"
          className="gap-1 border-green-400 bg-green-600/10 hover:bg-green-600/20"
          onClick={() => {
            setOpenBuy(true)
          }}
        >
          <ChevronUp className="size-5 text-green-400" />
          <span>Buy</span>
        </Button>
        <Button
          size="lg"
          variant="outline"
          className="gap-1 border-red-400 bg-red-600/10 hover:bg-red-600/20"
          onClick={() => {
            setOpenSell(true)
          }}
        >
          <ChevronDown className="size-5 text-red-400" />
          <span>Sell</span>
        </Button>
        <Button onClick={() => setOpenPending(true)} size="lg" variant="outline" className="gap-1 border bg-muted hover:bg-muted/20">
          <MdPendingActions className="size-5 shrink-0" />
          <span>Pending</span>
        </Button>
      </div>

      <DynamicDialog
        open={openBuy}
        close={closeBuy}
        label={<span className="flex gap-2">Virtual Buy</span>}
        description="Fill the form then click confirm"
        content=<>
          <div className="mt-4 grid grid-cols-4 items-center gap-2">
            <Label htmlFor="name" className="text-left">
              Set Time
            </Label>
            <AutocompleteDropdown
              options={userMessages}
              value={selectedMessage}
              onChange={setSelectedMessage}
              placeholder="Search message"
              dropdownLabel="Select message (optional)"
              setSearchText={() => { }}
            />
          </div>
          <div className="mt-4 grid grid-cols-4 items-center gap-2">
            <Label htmlFor="name" className="text-left">
              Metal Type
            </Label>
            <AutocompleteDropdownNew
              options={metalTypes}
              value={virtualBuyMetalType}
              onChange={setVirtualBuyMetalType}
              placeholder="Search metal type"
              dropdownLabel="Select metal type"
              setSearchText={(value) => {
                if (value.length <= 0) {
                  setMetalTypes(options);
                  return;
                }
                const mTypes = metalTypes.filter((mType) => mType.label.toLowerCase().includes(value.toLocaleLowerCase()))
                setMetalTypes(mTypes)
              }}
            />
          </div>
          <div className="mt-4 grid grid-cols-4 items-center gap-2">
            <Label htmlFor="quantity">Quantity</Label>
            <Input
              autoFocus
              value={virtualBuyQuantity}
              onChange={(e) => setVirtualBuyQuantity(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault(); // Prevent form from reloading or submitting
                  handleBuyTradeSubmit("BUY");
                }
              }}
              className="col-span-3"
              id="quantity"
              type="text"
              placeholder="Enter quantity"
            />
          </div>
        </>
        handleSubmit=
        <Button
          size="lg"
          variant="outline"
          onClick={() => handleBuyTradeSubmit("BUY")}
          className="mt-4 flex w-full flex-row gap-1 border-green-400 hover:bg-green-400/20"
        >
          <h3>Buying Price: </h3>
          <h3 className="text-green-400">{isLoadingBuySell ? <Loader2 className="w-4 h-4 animate-spin" /> : (selectedMessage?.length > 0 && customerAskrate > 0) ? "$" + (Number(customerAskrate).toFixed(2)) + " " + "🔴" : "$" + askRate?.toFixed(2)}</h3>
        </Button>
      />

      <DynamicDialog
        open={openSell}
        close={closeSell}
        label={<span className="flex gap-2">Virtual Sell</span>}
        description="Fill the form then click confirm"
        content=<>
          <div className="mt-4 grid grid-cols-4 items-center gap-2">
            <Label htmlFor="name" className="text-left">
              Set Time
            </Label>
            <AutocompleteDropdown
              options={userMessages}
              value={selectedMessage}
              onChange={setSelectedMessage}
              placeholder="Search message"
              dropdownLabel="Select message (optional)"
              setSearchText={() => { }}
            />
          </div>
          <div className="mt-4 grid grid-cols-4 items-center gap-2">
            <Label htmlFor="name" className="text-left">
              Metal Type
            </Label>
            <AutocompleteDropdownNew
              options={metalTypes}
              value={virtualSellMetalType}
              onChange={setVirtualSellMetalType}
              placeholder="Search metal type"
              dropdownLabel="Select metal type"
              setSearchText={(value) => {
                if (value.length <= 0) {
                  setMetalTypes(options);
                  return;
                }
                const mTypes = metalTypes.filter((mType) => mType.label.toLowerCase().includes(value.toLocaleLowerCase()))
                setMetalTypes(mTypes)
              }}
            />
          </div>
          <div className="mt-4 grid grid-cols-4 items-center gap-2">
            <Label htmlFor="quantity">Quantity</Label>
            <Input
              autoFocus
              value={virtualSellQuantity}
              onChange={(e) => setVirtualSellQuantity(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault(); // Prevent form from reloading or submitting
                  handleBuyTradeSubmit("SELL");
                }
              }}
              className="col-span-3"
              id="quantity"
              type="text"
              placeholder="Enter quantity"
            />
          </div>
        </>
        handleSubmit=
        <Button
          size="lg"
          variant="outline"
          disabled={isLoadingBuySell}
          onClick={() => handleBuyTradeSubmit("SELL")}
          className="mt-4 flex w-full flex-row gap-1 border-red-400 hover:bg-red-400/20"
        >
          <h3>Selling Price: </h3>
          <h3 className="text-red-400">{isLoadingBuySell ? <Loader2 className="animate-spin" /> : (selectedMessage?.length > 0 && customerBidrate > 0) ? "$" + Number(customerBidrate)?.toFixed(2) : bidRate?.toFixed(2)}</h3>
        </Button>
      />

      <DynamicDialog
        open={openPending}
        close={closePending}
        label={<span className="flex gap-2">Pending Sell</span>}
        description="Fill the form then click confirm"
        content={<DynamicTabs tabs={pendingTabs} />}
        handleSubmit={null}
      />
    </div>
  )
}

export default Actions
