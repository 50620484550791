export const convertWebMToWAV = async (webMBlob: Blob): Promise<ArrayBuffer> => {
    const audioContext = new AudioContext();
    const webMArrayBuffer = await webMBlob.arrayBuffer();
  
    // Decode WebM audio to PCM
    const audioBuffer = await audioContext.decodeAudioData(webMArrayBuffer);
  
    // Downsample to 44100 Hz
    const desiredSampleRate = 44100;
    const offlineAudioContext = new OfflineAudioContext(
      audioBuffer.numberOfChannels,
      audioBuffer.duration * desiredSampleRate,
      desiredSampleRate
    );
  
    const source = offlineAudioContext.createBufferSource();
    source.buffer = audioBuffer;
    source.connect(offlineAudioContext.destination);
    source.start(0);
  
    const renderedBuffer = await offlineAudioContext.startRendering();
  
    // Convert downsampled PCM data to WAV format
    const numberOfChannels = renderedBuffer.numberOfChannels;
    const length = renderedBuffer.length * numberOfChannels * 2 + 44;
    const wavBuffer = new ArrayBuffer(length);
    const view = new DataView(wavBuffer);
  
    // Write WAV header
    writeWAVHeader(view, renderedBuffer);
  
    // Write audio data
    const interleaved = interleaveChannels(renderedBuffer);
    const wavData = new Int16Array(interleaved.length);
    for (let i = 0; i < interleaved.length; i++) {
      wavData[i] = interleaved[i] * 32767; // Scale to 16-bit PCM
    }
  
    const wavBytes = new Uint8Array(wavData.buffer);
    new Uint8Array(wavBuffer).set(wavBytes, 44);
  
    return wavBuffer;
  };
  
  const writeWAVHeader = (view: DataView, audioBuffer: AudioBuffer) => {
    const channels = audioBuffer.numberOfChannels;
    const sampleRate = audioBuffer.sampleRate;
    const bitsPerSample = 16;
    const blockAlign = (channels * bitsPerSample) / 8;
    const byteRate = sampleRate * blockAlign;
  
    // RIFF chunk descriptor
    writeString(view, 0, 'RIFF');
    view.setUint32(4, 36 + audioBuffer.length * blockAlign, true); // File size
    writeString(view, 8, 'WAVE');
  
    // fmt subchunk
    writeString(view, 12, 'fmt ');
    view.setUint32(16, 16, true); // Subchunk1Size
    view.setUint16(20, 1, true); // AudioFormat (1 = PCM)
    view.setUint16(22, channels, true); // NumChannels
    view.setUint32(24, sampleRate, true); // SampleRate
    view.setUint32(28, byteRate, true); // ByteRate
    view.setUint16(32, blockAlign, true); // BlockAlign
    view.setUint16(34, bitsPerSample, true); // BitsPerSample
  
    // data subchunk
    writeString(view, 36, 'data');
    view.setUint32(40, audioBuffer.length * blockAlign, true); // Subchunk2Size
  };
  
  const writeString = (view: DataView, offset: number, string: string) => {
    for (let i = 0; i < string.length; i++) {
      view.setUint8(offset + i, string.charCodeAt(i));
    }
  };
  
  const interleaveChannels = (audioBuffer: AudioBuffer) => {
    const length = audioBuffer.length * audioBuffer.numberOfChannels;
    const result = new Float32Array(length);
    const input = [];
    for (let i = 0; i < audioBuffer.numberOfChannels; i++) {
      input.push(audioBuffer.getChannelData(i));
    }
    let index = 0;
    for (let i = 0; i < audioBuffer.length; i++) {
      for (let j = 0; j < audioBuffer.numberOfChannels; j++) {
        result[index++] = input[j][i];
      }
    }
    return result;
  };  